import React, { useEffect, useState } from 'react';
import { Dialog, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import useIsDesktop from 'src/hooks/useIsDesktop';
import useAuth from 'src/hooks/useAuth';
import useCasrInfo from 'src/hooks/useCasrInfo';
import useSettings, { SettingsKeys } from 'src/hooks/useSettings';
import { recognitionPath } from 'src/lib/routing';

const USER_TASK_NOTIFY_RECOGNITION_UNLOCKED =
  'notify_casr_recognition_unlocked';

const ACOUSTIC_MODEL_VERSION = 'acoustic_model_version';

const StyledContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    width: 530,
    height: 730,
  },
}));

const StyledContent = styled(Box)(({ theme }) => ({
  minHeight: '40%',
  background: '#EF7B43',
  color: 'white',
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(2),
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '32px',
  textAlign: 'center',
}));

const StyledBody = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  textAlign: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(5.75),
  maxWidth: theme.spacing(20),
  borderRadius: '6px',
  width: '100%',
  fontSize: '16px',
  fontWeight: 700,
}));

const Image = styled('img')(({ theme }) => ({
  display: 'block',
}));

const getModalContent = (action) => (
  <>
    <StyledTitle>
      Congratulations!
      <br />
      Your personal voice profile is updated.
    </StyledTitle>
    <StyledBody mt={2}>
      Remember, Voiceitt is still learning your unique speech, use Voiceitt or
      continue recording to improve your personal voice profile!
    </StyledBody>
    <StyledButton
      variant="contained"
      sx={{ mt: 'auto', mb: 2 }}
      onClick={action}
    >
      Use Voiceitt
    </StyledButton>
  </>
);

export const RecognitionUnlocked = () => {
  const isDesktop = useIsDesktop();
  const { userTasks, completeUserTask } = useAuth();
  const { acousticModel } = useCasrInfo();
  const settings = useSettings();
  const navigate = useNavigate();
  const [content, setContent] = useState();

  const recognitionMode = settings.state[SettingsKeys.recognitionMode];

  useEffect(() => {
    if (content) {
      return;
    }
    const isModelFetched = acousticModel !== undefined;
    if (!isModelFetched) {
      return;
    }

    const isRecognitionUnlocked = (userTasks || []).includes(
      USER_TASK_NOTIFY_RECOGNITION_UNLOCKED,
    );
    if (isRecognitionUnlocked) {
      const modalAction = async () => {
        await completeUserTask(USER_TASK_NOTIFY_RECOGNITION_UNLOCKED);

        settings.actions.set(SettingsKeys.notificationStatus, {
          ...settings.state[SettingsKeys.notificationStatus],
          [ACOUSTIC_MODEL_VERSION]: acousticModel.version,
        });

        setContent();

        navigate(recognitionPath(recognitionMode));
      };
      setContent(getModalContent(modalAction));
      return;
    }

    const skipInitial = true; //to avoid unneeded popups after re-login
    const notifiedAcousticModelVersion =
      settings.state[SettingsKeys.notificationStatus][ACOUSTIC_MODEL_VERSION];
    if (skipInitial && acousticModel && !notifiedAcousticModelVersion) {
      settings.actions.set(SettingsKeys.notificationStatus, {
        ...settings.state[SettingsKeys.notificationStatus],
        [ACOUSTIC_MODEL_VERSION]: acousticModel.version,
      });
      return;
    }
    const isModelUpdated =
      acousticModel?.version &&
      notifiedAcousticModelVersion !== acousticModel?.version;
    if (isModelUpdated) {
      const modalAction = async () => {
        settings.actions.set(SettingsKeys.notificationStatus, {
          ...settings.state[SettingsKeys.notificationStatus],
          [ACOUSTIC_MODEL_VERSION]: acousticModel.version,
        });

        setContent();

        navigate(recognitionPath(recognitionMode));
      };
      setContent(getModalContent(modalAction));
      return;
    }
  }, [userTasks, acousticModel?.version, content]);

  return (
    <Dialog
      open={!!content}
      fullScreen={!isDesktop}
      sx={{
        zIndex: 1301, //Over all other popovers
      }}
    >
      <StyledContainer>
        <Box
          sx={(theme) => ({
            flexGrow: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
          })}
        >
          <Box flex="1"></Box>
          <Box position="relative">
            <Box position="relative">
              <Image
                src="/img/voiceitt_confetti.png"
                sx={{ maxWidth: '100%' }}
              />
            </Box>
          </Box>
        </Box>

        <StyledContent>{content}</StyledContent>
      </StyledContainer>
    </Dialog>
  );
};

export default RecognitionUnlocked;
